const initialState = () => ({
    flags: {
        isMultipleOrders: false,
        showRecordMaterialsModal: false,
        showOrdersGroupMaterialModal: false,
        showMaterialHistoryModal: false,
        showAddMaterialsModal: false,
        showOperationsModal: false
    },
    materialHistoryModal: {
        history: []
    },
    recordMaterialsModal: {
        hasChanges: false,
        selectedTab: "materials",
        valid: null,
        materials: [],
        history: [],
        filterInput: [],
        filteredMaterialsList: [],
        orderGroupMaterials: [],
        listScannedMaterials: []
    },
    ordersGroupMaterialModal: {
        hasChanges: false,
        material: {}
    },
    addMaterialsModal: {
        filterInput: [],
        filteredMaterialsList: [],
        materials: [],
        selectedMaterialIds: []
    },
    operationsModal: {
        material: {},
        filterInput: [],
        filteredOperationsList: [],
        operations: [],
        selectedOperationIds: []
    }
});

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        // operationsModal
        UPDATE_FLAGS_SHOW_OPERATIONS_MODAL(state, payload) {
            state.flags.showOperationsModal = payload;
        },
        UPDATE_OPERATIONS_MODAL_MATERIAL(state, payload) {
            state.operationsModal.material = payload;
        },
        UPDATE_OPERATIONS_MODAL_OPERATIONS(state, payload) {
            state.operationsModal.operations = payload;
        },
        UPDATE_OPERATIONS_MODAL_SELECTED_OPERATION_IDS(state, payload) {
            state.operationsModal.selectedOperationIds = payload;
        },
        UPDATE_OPERATIONS_MODAL_FILTER_INPUT(state, payload) {
            state.operationsModal.filterInput = payload;
        },
        UPDATE_OPERATIONS_MODAL_FILTERED_OPERATIONS_LIST(state, payload) {
            state.operationsModal.filteredOperationsList = payload;
        },
        RESET_OPERATIONS_MODAL(state) {
            Object.assign(state.operationsModal, initialState().operationsModal);
        },

        // addMaterialsModal
        UPDATE_FLAGS_SHOW_ADD_MATERIALS_MODAL(state, payload) {
            state.flags.showAddMaterialsModal = payload;
        },
        UPDATE_ADD_MATERIAL_MODAL_SELECTED_MATERIAL_IDS(state, payload) {
            state.addMaterialsModal.selectedMaterialIds = payload;
        },
        UPDATE_ADD_MATERIAL_MODAL_SELECTED_OPERATION_IDS(state, payload) {
            state.addMaterialsModal.selectedOperationIds = payload;
        },
        UPDATE_ADD_MATERIAL_MODAL_SELECTED_OPERATION_IDS(state, payload) {
            state.addMaterialsModal.selectedOperationIds = payload;
        },
        UPDATE_ADD_MATERIAL_MODAL_MATERIALS(state, payload) {
            state.addMaterialsModal.materials = payload;
        },
        UPDATE_ADD_MATERIAL_MODAL_FILTER_INPUT(state, payload) {
            state.addMaterialsModal.filterInput = payload;
        },
        UPDATE_ADD_MATERIAL_MODAL_FILTERED_MATERIALS_LIST(state, payload) {
            state.addMaterialsModal.filteredMaterialsList = payload;
        },
        RESET_ADD_MATERIALS_MODAL(state) {
            Object.assign(state.addMaterialsModal, initialState().addMaterialsModal);
        },

        // materialHistoryModal
        UPDATE_MATERIAL_HISTORY_MODAL_HISTORY(state, payload) {
            state.materialHistoryModal.history = payload;
        },
        UPDATE_FLAGS_SHOW_MATERIAL_HISTORY_MODAL(state, payload) {
            state.flags.showMaterialHistoryModal = payload;
        },
        RESET_MATERIAL_HISTORY_MODAL(state) {
            Object.assign(state.materialHistoryModal, initialState().materialHistoryModal);
        },

        // ordersGroupMaterialModal
        UPDATE_ORDERS_GROUP_MATERIAL_MODAL_HAS_CHANGES(state, payload) {
            state.ordersGroupMaterialModal.hasChanges = payload;
        },
        UPDATE_ORDERS_GROUP_MATERIAL_MODAL_MATERIAL(state, payload) {
            state.ordersGroupMaterialModal.material = payload;
        },
        UPDATE_ORDERS_GROUP_MATERIAL_MODAL_OPERATIONS_MATERIAL(state, payload) {
            state.ordersGroupMaterialModal.material.operations = payload;
        },
        UPDATE_ORDERS_GROUP_MATERIAL_MODAL_SELECTED_TAB(state, payload) {
            state.recordMaterialsModal.selectedTab = payload;
        },
        UPDATE_FLAGS_SHOW_ORDERS_GROUP_MATERIAL_MODAL(state, payload) {
            state.flags.showOrdersGroupMaterialModal = payload;
        },
        RESET_ORDERS_GROUP_MATERIAL_MODAL(state) {
            Object.assign(state.ordersGroupMaterialModal, initialState().ordersGroupMaterialModal);
        },

        // recordMaterialsModal
        UPDATE_FLAGS_SHOW_RECORD_MATERIALS_MODAL(state, payload) {
            state.flags.showRecordMaterialsModal = payload;
        },
        UPDATE_RECORD_MATERIALS_MODAL_HAS_CHANGES(state, payload) {
            state.recordMaterialsModal.hasChanges = payload;
        },
        UPDATE_RECORD_MATERIALS_MODAL_MATERIALS(state, payload) {
            state.recordMaterialsModal.materials = payload;
        },
        UPDATE_RECORD_MATERIALS_MODAL_HISTORY(state, payload) {
            state.recordMaterialsModal.history = payload;
        },
        UPDATE_RECORD_MATERIALS_MODAL_ORDER_GROUP_MATERIALS(state, payload) {
            state.recordMaterialsModal.orderGroupMaterials = payload;
        },
        UPDATE_FLAGS_IS_MULTIPLE_ORDERS(state, payload) {
            state.flags.isMultipleOrders = payload;
        },
        UPDATE_RECORD_MATERIALS_MODAL_FILTER_INPUT(state, payload) {
            state.recordMaterialsModal.filterInput = payload;
        },
        UPDATE_RECORD_MATERIALS_MODAL_FILTERED_MATERIALS_LIST(state, payload) {
            state.recordMaterialsModal.filteredMaterialsList = payload;
        },
        UPDATE_SCANNED_MATERIALS_MODAL_BAR_CODE(state, payload) {
            state.recordMaterialsModal.listScannedMaterials = payload;
        },
        RESET_RECORD_MATERIALS_MODAL(state) {
            Object.assign(state.recordMaterialsModal, initialState().recordMaterialsModal);
        },

        // general
        RESET_STATES(state) {
            Object.assign(state, initialState());
        }
    },
    getters: {
        recordMaterialsModal: (state) => state.recordMaterialsModal,
        ordersGroupMaterialModal: (state) => state.ordersGroupMaterialModal,
        materialHistoryModal: (state) => state.materialHistoryModal,
        addMaterialsModal: (state) => state.addMaterialsModal,
        operationsModal: (state) => state.operationsModal,
        flags: (state) => state.flags
    },
    actions: {
        backToRecordMaterialsModal({ commit }) {
            commit("UPDATE_FLAGS_SHOW_ORDERS_GROUP_MATERIAL_MODAL", false);
            commit("UPDATE_FLAGS_SHOW_ADD_MATERIALS_MODAL", false);
            commit("UPDATE_FLAGS_SHOW_RECORD_MATERIALS_MODAL", true);
            commit("RESET_ORDERS_GROUP_MATERIAL_MODAL");
            commit("RESET_ADD_MATERIALS_MODAL");
        },
        backToAddMaterialsModal({ commit }) {
            commit("UPDATE_FLAGS_SHOW_OPERATIONS_MODAL", false);
            commit("UPDATE_FLAGS_SHOW_ADD_MATERIALS_MODAL", true);
            commit("RESET_OPERATIONS_MODAL");
        },
        closeOrdersGroupMaterialModal({ commit }) {
            commit("RESET_ORDERS_GROUP_MATERIAL_MODAL");
            commit("RESET_RECORD_MATERIALS_MODAL");
            commit("UPDATE_FLAGS_SHOW_ORDERS_GROUP_MATERIAL_MODAL", false);
        },
        closeRecordMaterialsModal({ commit }) {
            commit("RESET_RECORD_MATERIALS_MODAL");
            commit("UPDATE_FLAGS_SHOW_RECORD_MATERIALS_MODAL", false);
        },
        closeMaterialHistoryModal({ commit }) {
            commit("RESET_MATERIAL_HISTORY_MODAL");
            commit("UPDATE_FLAGS_SHOW_MATERIAL_HISTORY_MODAL", false);
        },
        closeOperationsModal({ commit }) {
            commit("RESET_OPERATIONS_MODAL");
            commit("UPDATE_FLAGS_SHOW_OPERATIONS_MODAL", false);
        },
        closeAddMaterialsModal({ commit }) {
            commit("RESET_ADD_MATERIALS_MODAL");
            commit("RESET_OPERATIONS_MODAL");
            commit("UPDATE_FLAGS_SHOW_RECORD_MATERIALS_MODAL", true);
            commit("UPDATE_FLAGS_SHOW_ADD_MATERIALS_MODAL", false);
        },
        openOrdersGroupMaterialModal({ commit }) {
            commit("UPDATE_FLAGS_SHOW_ORDERS_GROUP_MATERIAL_MODAL", true);
            commit("UPDATE_FLAGS_SHOW_RECORD_MATERIALS_MODAL", false);
        },
        openRecordMaterialsModal({ commit }) {
            commit("UPDATE_FLAGS_SHOW_RECORD_MATERIALS_MODAL", true);
        },
        openMaterialHistoryModal({ commit }) {
            commit("UPDATE_FLAGS_SHOW_MATERIAL_HISTORY_MODAL", true);
        },
        openAddMaterialsModal({ commit }) {
            commit("UPDATE_FLAGS_SHOW_RECORD_MATERIALS_MODAL", false);
            commit("UPDATE_FLAGS_SHOW_ADD_MATERIALS_MODAL", true);
        },
        openOperationsModal({ commit }) {
            commit("UPDATE_FLAGS_SHOW_ADD_MATERIALS_MODAL", false);
            commit("UPDATE_FLAGS_SHOW_OPERATIONS_MODAL", true);
        }
    }
};
